import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import ErrorMessage from '../../../ui/ErrorMessage';
import { useV2Query } from '../../../useApollo';
import { SpendingMonitorDocument, SpendingMonitorQuery } from '../FetchSpend.query.v2.generated';
import * as api from './api';
import Recurring, { RecurringData } from './Recurring';

const DataContainer = ({ claims }: any = {}) => {
    const [processing, setProcessing] = useState<boolean>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [spendingData, setSpendingData] = useState<SpendingMonitorQuery>();

    const { refetch: fetchSpend } = useV2Query(SpendingMonitorDocument, {
        // manually control fetching via polling logic
        skip: true,
    });
    useEffect(() => {}, []);

    const onSubmit = async (userId: string): Promise<RecurringData> => {
        setProcessing(true);
        try {
            const { body, status } = await api.getRecurring(userId);
            const showBalances = claims.int?.includes('cda');
            if (showBalances) {
                const { data: spendData } = await fetchSpend({ userId });
                setSpendingData(spendData);
            }
            if (status >= 400) {
                setErrorMessage(
                    `received code ${status} while retrieving user ${userId} (${new Date().getTime()})`
                );
                setProcessing(false);
                return {};
            }
            setProcessing(false);
            return { transactions: body };
        } catch (err) {
            setErrorMessage(`${err} (${new Date().getTime()})`);
        }
        setProcessing(false);
        return {};
    };

    return (
        <>
            <ErrorMessage message={errorMessage} />
            <Recurring
                claims={claims}
                processing={processing}
                spendingData={spendingData}
                onSubmit={onSubmit}
            />
        </>
    );
};

export default connect((state: any) => {
    const { user } = state.identity.current;
    return {
        claims: user.claims,
    };
})(DataContainer);
