import { Routes as UserRoutes } from '../../Users';
import { Routes as BillNegotiationRoutes } from '../../BillNegotiation';
import { Routes as SubscriptionCancellationRoutes } from '../../SubscriptionCancellations';
import { Routes as VendorRoutes } from '../../Vendors';
import { Routes as ContentRoutes } from '../../Content';
import { Routes as FormRoutes } from '../../Forms';
import { Routes as DataRoutes } from '../../Data';
import { Routes as RecurringRoutes } from '../../Recurring';

const exported = {
    users: {
        path: '/dashboard/users',
        routes: UserRoutes,
        groups: ['internal-tools-manage-users'],
    },
    billNegotiation: {
        path: '/dashboard/billnegotiation',
        routes: BillNegotiationRoutes,
        groups: ['internal-tools-bill-negotiation'],
    },
    subscriptionCancellations: {
        path: '/dashboard/subscriptioncancel',
        routes: SubscriptionCancellationRoutes,
        groups: ['internal-tools-subscription-cancellation'],
    },
    vendors: {
        path: '/dashboard/vendors',
        routes: VendorRoutes,
        groups: ['internal-tools-manage-vendors', 'internal-tools-view-vendors'],
    },
    content: {
        path: '/dashboard/content',
        routes: ContentRoutes,
        groups: ['internal-tools-content-authoring'],
    },
    forms: {
        path: '/dashboard/forms',
        routes: FormRoutes,
        groups: ['internal-tools-manage-vendors', 'internal-tools-view-vendors'],
    },
    data: {
        path: '/dashboard/data',
        routes: DataRoutes,
        groups: ['internal-tools-engineering'],
    },
    recurring: {
        path: '/dashboard/recurring',
        routes: RecurringRoutes,
        groups: ['internal-tools-engineering', 'internal-tools-view-recurring'],
    },
};

export default exported;

export const { users, billNegotiation, subscriptionCancellations, vendors, content, forms } =
    exported;
