import { connect } from 'react-redux';
import Dashboard from './Dashboard';

export default connect((state: any) => {
    const { user } = state.identity.current;
    return {
        claims: user.claims,
        groups: user.groups,
    };
})(Dashboard);
