import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../types/gqlTypes.v2';

const defaultOptions = {} as const;
export type SpendingMonitorQueryVariables = Types.Exact<{
    userId: Types.Scalars['ID']['input'];
}>;

export type SpendingMonitorQuery = {
    __typename?: 'Query';
    projectedBalances: {
        __typename?: 'ProjectedBalances';
        month: { __typename?: 'PeriodBalances'; cash: number; income: number; spent: number };
        week: { __typename?: 'PeriodBalances'; cash: number; income: number; spent: number };
    };
    balances: {
        __typename?: 'Balances';
        assets: { __typename?: 'BalanceAssets'; current: number; available: number };
        liabilities: {
            __typename?: 'BalanceLiabilities';
            available: number;
            current: number;
            limit: number;
        };
        credit: { __typename?: 'BalanceCredit'; current: number; available: number; limit: number };
    };
};

export const SpendingMonitorDocument = gql`
    query SpendingMonitor($userId: ID!) {
        projectedBalances(userId: $userId) {
            month {
                cash
                income
                spent
            }
            week {
                cash
                income
                spent
            }
        }
        balances(userId: $userId) {
            assets {
                current
                available
            }
            liabilities {
                available
                current
                limit
            }
            credit {
                current
                available
                limit
            }
        }
    }
`;

/**
 * __useSpendingMonitorQuery__
 *
 * To run a query within a React component, call `useSpendingMonitorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpendingMonitorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpendingMonitorQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSpendingMonitorQuery(
    baseOptions: Apollo.QueryHookOptions<SpendingMonitorQuery, SpendingMonitorQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<SpendingMonitorQuery, SpendingMonitorQueryVariables>(
        SpendingMonitorDocument,
        options
    );
}
export function useSpendingMonitorLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<SpendingMonitorQuery, SpendingMonitorQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<SpendingMonitorQuery, SpendingMonitorQueryVariables>(
        SpendingMonitorDocument,
        options
    );
}
export type SpendingMonitorQueryHookResult = ReturnType<typeof useSpendingMonitorQuery>;
export type SpendingMonitorLazyQueryHookResult = ReturnType<typeof useSpendingMonitorLazyQuery>;
export type SpendingMonitorQueryResult = Apollo.QueryResult<
    SpendingMonitorQuery,
    SpendingMonitorQueryVariables
>;
