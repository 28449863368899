import { ApolloClient, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache } from '@apollo/client/cache';
import * as sessionManager from './services/session';

export type ApolloClientTypes = 'v1' | 'v2';

const authLink = setContext(async (_, { headers }) => {
    const s = sessionManager.get();
    if (s) {
        return {
            headers: {
                ...headers,
                authorization: `Bearer ${s.tokens.hiatus}`,
            },
        };
    }

    return {};
});

export const v1ApolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(
        createHttpLink({
            uri: '/graphql',
        })
    ),
    name: 'hiatus.tools',
});

export const v2ApolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(
        createHttpLink({
            uri: '/graphql-v2',
        })
    ),
    name: 'hiatus.tools',
});

export const useApolloClient = () => {
    return {
        getClient: (type: ApolloClientTypes) => {
            v1ApolloClient.setLink(
                authLink.concat(
                    createHttpLink({
                        uri: '/graphql',
                    })
                )
            );
            v2ApolloClient.setLink(
                authLink.concat(
                    createHttpLink({
                        uri: '/graphql-v2',
                    })
                )
            );

            const apolloClient = type === 'v1' ? v1ApolloClient : v2ApolloClient;

            return apolloClient;
        },
    };
};
