/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    ApolloCache,
    DefaultContext,
    DocumentNode,
    MutationHookOptions,
    OperationVariables,
    QueryHookOptions,
    TypedDocumentNode,
    useMutation,
    useQuery,
} from '@apollo/client';
import { ApolloClientTypes, useApolloClient } from './graphqlClient';

// An abstraction layer to make sure the right Apollo client is used
const useGenericQuery =
    (type: ApolloClientTypes) =>
    <TData = any, TVariables extends OperationVariables = OperationVariables>(
        query: DocumentNode | TypedDocumentNode<TData, TVariables>,
        options?: QueryHookOptions<TData, TVariables>
    ) => {
        const { getClient } = useApolloClient();
        const client = getClient(type);

        return useQuery<TData, TVariables>(query, {
            ...options,
            client,
        });
    };

const useGenericMutation =
    (type: ApolloClientTypes) =>
    <
        TData = any,
        TVariables = OperationVariables,
        TContext = DefaultContext,
        TCache extends ApolloCache<any> = ApolloCache<any>
    >(
        mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
        options?: MutationHookOptions<TData, TVariables, TContext, TCache>
    ) => {
        const { getClient } = useApolloClient();
        const client = getClient(type);
        return useMutation<TData, TVariables, TContext, TCache>(mutation, {
            ...options,
            client,
        });
    };

export const useV1Query = useGenericQuery('v1');
export const useV2Query = useGenericQuery('v2');

export const useV1Mutation = useGenericMutation('v1');
export const useV2Mutation = useGenericMutation('v2');
